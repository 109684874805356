// 引入three.js
import * as THREE from "three";
import {
  AxesHelper,
  PlaneGeometry,
  PointsMaterial,
  ShaderMaterial,
} from "three";
// 导入轨道控制器
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";

import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";

import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";

// 导入动画库
import gsap, { random } from "gsap";
// 导入dat.gui
// import * as dat from "dat.gui";

const AllModule = (dom: any, modelName: any) => {
  //   const gui = new dat.GUI();
  //    目标: shader 着色器

  // 1.创建场景
  const scene = new THREE.Scene();
  // 2. 创建相机
  const camera = new THREE.PerspectiveCamera(
    90,
    window.innerWidth / window.innerHeight,
    0.1,
    1000
  );

  //3. 设置相机位置
  // camera.position.set(0, 2, 11);
  camera.position.z = 2;
  //   更新相机
  camera.aspect = window.innerWidth / window.innerHeight;
  //   添加相机
  scene.add(camera);

  //   添加坐标轴辅助器
  // const axeshelper = new AxesHelper(5);
  // // 添加到场景中
  // scene.add(axeshelper);

  //添加光源 //会照亮场景里的全部物体（氛围灯），前提是物体是可以接受灯光的，这种灯是无方向的，即不会有阴影。
  const ambient = new THREE.AmbientLight(0xffffff, 0.4);
  const light = new THREE.DirectionalLight(0xffffff, 1); //点光源，color:灯光颜色，intensity:光照强度
  scene.add(ambient);
  light.position.set(200, 300, 400);
  scene.add(light);

  // const rgbeLoader = new RGBELoader();

  // rgbeLoader.loadAsync("/hdr/10.hdr").then((texture) => {
  //   texture.mapping = THREE.EquirectangularReflectionMapping;
  //   //将加载的材质texture设置给背景和环境
  //   scene.background = texture;
  //   scene.environment = texture;
  // });

  // 设置加载管理器
  // const loadingManager = new THREE.LoadingManager(
  //   event.onLoad,
  //   event.onProgress,
  //   event.onError
  // );

  let paocheModel = <any>null;
  let Model = <any>null;
  if (modelName == "paoche") {
    let loader = new GLTFLoader();
    // https://ydd-oss1.oss-cn-hangzhou.aliyuncs.com/dirigible.glb
    loader.load(
      `https://ydd-oss1.oss-cn-hangzhou.aliyuncs.com/guanWan/${modelName}.glb`,
      (glft) => {
        paocheModel = glft;
        glft.scene.scale.set(0.5, 0.5, 0.5);
        scene.add(glft.scene);
        renderer.render(scene, camera);
      }
    );
  } else {
    //  fbx
    // 创建一个 FBXLoader 实例
    const fbxLoader = new FBXLoader();
    // 使用 load 方法加载 FBX 文件，并处理加载完成后的回调函数
    fbxLoader.load(
      `https://ydd-oss1.oss-cn-hangzhou.aliyuncs.com/guanWan/${modelName}.fbx`,
      (object) => {
        Model = object;
        // 修改材质贴图
        object.traverse((child) => {
          if (child instanceof THREE.Mesh) {
            object.scale.set(0.005, 0.005, 0.005);
          }
        });

        // 将加载的模型添加到场景中
        scene.add(object);
      }
    );
  }

  // 创建一个 OBJLoader 实例和 MTLLoader 实例
  // const objLoader = new OBJLoader();
  // const mtlLoader = new MTLLoader();

  // // 使用 load 方法加载 OBJ 文件和其关联的 MTL 材质文件，并处理加载完成后的回调函数
  // mtlLoader.load("/obj/GC.mtl", (materials) => {
  //   materials.preload();
  //   objLoader.setMaterials(materials);
  //   objLoader.load("/obj/GC.obj", (object) => {
  //     object.scale.set(0.5, 0.5, 0.5);
  //     scene.add(object);
  //   });
  // });

  //   初始化渲染器   alpha: true渲染器透明
  const renderer = new THREE.WebGLRenderer({ alpha: true });
  // const renderer = new THREE.WebGLRenderer();
  // 设置渲染的尺寸大小
  // renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.setSize(1200, 600);

  // 开启场景中的阴影贴图
  renderer.shadowMap.enabled = true;
  // renderer.physicallyCorrectLights = true;

  dom.appendChild(renderer.domElement); // 将数据传递到界面

  //   创建轨道控制器
  const controls = new OrbitControls(camera, renderer.domElement);
  // // 设置控制器阻尼 让控制器更有真实效果 必须在动画循环里调用.update()
  controls.enableDamping = true;

  //是否可以缩放
  controls.enableZoom = false;

  // 设置时钟
  const clock = new THREE.Clock();

  //   根据浏览器每一帧渲染
  const animate = () => {
    const elapsedTime = clock.getElapsedTime();
    controls.update();
    if (paocheModel) {
      paocheModel.scene.rotation.y += 0.001;
    }
    if (Model) {
      Model.rotation.y += 0.001;
    }
    // 使用渲染器，通过相机将场景渲染进来
    renderer.render(scene, camera);
    // 渲染下一帧的时候就会调用render函数
    requestAnimationFrame(animate);
  };
  animate();

  document.addEventListener("contextmenu", function (event) {
    // event.preventDefault();
  });

  // controls.enableRotate = false; // 禁止左键旋转
  // 禁用右键拖动
  //   controls.mouseButtons = {
  //     LEFT: THREE.MOUSE.ROTATE,
  //     MIDDLE: THREE.MOUSE.PAN,
  //     RIGHT: null, // 设置为null禁用右键拖动
  //   };

  // 鼠标右键按下时，禁止默认行为
  document.addEventListener("mousedown", function (event) {
    if (event.button === 2) {
      // event.preventDefault();
    }
  });
};
export default AllModule;
